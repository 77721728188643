.container {
    background-color: #EBEBF0;
    width: 100%;
    height: 100%;
    min-width: 1200px;
}

.header {
    width: 100%;
    height: 80px;
    line-height: 80px;
    background-color: #fff;
}

.headerLeft {
    display: inline-block;
    width: 50%;
    vertical-align: top;
}

.headerLeft img {
    height: 31px;
    vertical-align: sub;
    margin-left: 30px;
}

.title {
    font-size: 24px;
    color: rgba(51,51,51,1);
    font-weight: 400;
    margin-left: 20px;
}

.headerRight {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    text-align: right;
}

.body {
    height: calc(100% - 120px);
    width: 1200px;
    margin: 40px auto 0;
    background-color: #fff;
    overflow: auto;
    position: relative;
}

.back {
    position: absolute;
    top: 30px;
    right: 50px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(55,79,199,1);
    cursor: pointer;
}

.content {
    padding: 50px;
}

.menuBlock {
    margin-bottom: 40px;
}

.menuBlockTitle {
    font-size: 20px;
    font-weight: 400;
    color: rgba(51,51,51,0.65);
    margin-bottom: 20px;
}

.menuItems {

}

.menuItemLine {
    width: 1100px;
}

.menuItemLine:not(:last-child) {
    margin-bottom: 22px;
}

.menuItem {
    display: inline-block;
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
    border-radius: 8px;
    width: 350px;
    height: 100px;
    line-height: 100px;
    padding: 0 20px 0 28px;
    cursor: pointer;
}

.menuItem:not(:last-child) {
    margin-right: 22px;
}

.menuItemIcon {
    width: 42px;
    height: 42px;
    vertical-align: middle;
    margin-top: -10px;
}

.menuItemTitle {
    font-size: 20px;
    font-weight: 600;
    color: rgba(51,51,51,1);
    display: inline-block;
    width: 236px;
    padding-left: 16px;
}

.menuItemNext {
    width: 24px;
    height: 24px;
    vertical-align: sub;
    opacity: 0.2;
}