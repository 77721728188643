.ant-carousel {
    height: 100%;
}

.ant-carousel .slick-slider {
    height: 100%;
}

.ant-carousel .slick-slider .slick-list {
    height: 100%;
}